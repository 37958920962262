import * as React from "react";
import { Navigation } from "../components/global/nav"
import { Footer } from '../components/global/footer'
import "../styles.css"
import SEO from "../components/global/seo";
import CookiePrompt from "../components/global/cookies-prompt";
import Breadcrumbs from "../components/global/breadcrumbs";

const NewBoiler = () => {
   return (
       <main>
           <SEO
               title="Get a new boiler through Smart Plan"
               slug="new-boiler"
               metadescription="Boiler seen better days? With Smart Plan you can get a new boiler for as little as £30 per month, with a service plan included for 10 years!"
               image=""
               titleTemplate="%s"
           />
           <CookiePrompt/>
           <Navigation />

               <div className="standard-title-container default">
                   <div className="standard-title-inner-container body-inner-width">
                       <div className="standard-title-inner-content">
                           <h1 id="title">Get a new boiler</h1>
                           <p>Has your boiler seen better days? With Smart Plan you can get a new boiler for as little as £30 per month, with a 10 year service plan included.</p>
                       </div>
                   </div>
               </div>

               <div className="standard-container">
                   <div className="standard-inner-container body-inner-width">
                       <Breadcrumbs 
                           location="new-boiler"
                       />
                       <p>This page is under construction</p>
                   </div>
               </div>
           <Footer />
       </main>
   )
}

export default NewBoiler